import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { ScoreboardOptionProvider } from "./contexts/ScoreboardOptionContext.tsx";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ScoreboardOptionProvider>
      <App />
    </ScoreboardOptionProvider>
  </React.StrictMode>
);
